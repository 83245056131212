// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
  current_lang: 'en',
  autoclear_cookies: true,           // default: false
  page_scripts: true,            // default: false

  mode: 'opt-out',               // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  delay: 6000,                 // default: 0
  // auto_language: null           // default: null; could also be 'browser' or 'document'
  // autorun: true,              // default: true
  // force_consent: false,           // default: false
  // hide_from_bots: true,           // default: true
  // remove_cookie_tables: false       // default: false
  // cookie_name: 'cc_cookie',         // default: 'cc_cookie'
  // cookie_expiration: 182,         // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,     // default: current domain
  // cookie_path: '/',             // default: root
  // cookie_same_site: 'Lax',        // default: 'Lax'
  // use_rfc_cookie: false,          // default: false
  revision: 0,                 // default: 0

  onFirstAction: function(user_preferences, cookie){
    // callback triggered only once
    console.log(user_preferences, cookie)
  },

  onAccept: function (cookie) {
    // ...
    console.log(cookie)
  },

  onChange: function (cookie, changed_preferences) {
    if (cookie.categories.includes('analytics')) {
        alert('GOOGLE')
      const newScript = document.createElement("script");
      newScript.type = "text/javascript";
      newScript.setAttribute("async", "true");
      newScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-3HVCGV0Q2G");
      document.documentElement.firstChild.appendChild(newScript);

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-3HVCGV0Q2G');
    }
  },

  languages: {
    'en': {
      consent_modal: {
        title: 'We use cookies!',
        description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all'        // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject all',
          role: 'accept_necessary'    // 'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          {col1: 'Name'},
          {col2: 'Domain'},
          {col3: 'Expiration'},
          {col4: 'Description'}
        ],
        blocks: [
          {
            title: 'Cookie usage 📢',
            description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://yinyang-interactive.com/privacy.html" class="cc-link">privacy policy</a>.'
          }, {
            title: 'Strictly necessary cookies',
            description: 'These temporary cookies are essential for the proper functioning of my website. This data gets cleared once you close the browser window.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true,
            }
          }, {
            title: 'Performance and Analytics cookies',
            description: 'These cookies allow the website to remember the choices you have made in the past and they help us to improve this awesome website.',
            toggle: {
              value: 'analytics',
              enabled: true,
              readonly: false,
            },
            cookie_table: [       // list of all expected cookies
              {
                col1: '^_ga',     // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'Google Analytics',
                is_regex: true
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 day',
                col4: 'Google Analytics',
              }
            ]
          }, {
            title: 'More information',
            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="https://yinyang-interactive.com/#contact">contact us</a>.',
          }
        ]
      }
    }
  }
});
